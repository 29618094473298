import { FC } from 'react';
import { Input } from 'src/components/kit/Input';
import { AuthFormContainer } from 'src/containers/AuthFormContainer';
import { useForm } from 'react-hook-form';
import { Checkbox } from 'src/components/kit/CheckBox';
import { AppLink } from 'src/components/kit/AppLink';
import { Button } from 'src/components/kit/Button';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'src/hooks/useRedux';
import { fetchShops } from 'src/store/shops/actions';
import { fetchOnBoarding, fetchTariffs } from 'src/store/user/actions';
import AuthService from 'src/services/AuthService';

export const Login: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'all' });

  const onSubmit = handleSubmit(async data => {
    const { login, password } = data;

    await AuthService.login(login, password, dispatch).then(async () => {
      navigate('/dashboard/shops');
      await dispatch(fetchShops());
      // await dispatch(fetchTariffs());
      await dispatch(fetchOnBoarding());
    });
  });

  return (
    <AuthFormContainer onSubmit={onSubmit} title="Вход" classNames="justify-center">
      <div className="flex flex-col gap-5 w-full">
        <Input
          register={register}
          data={{ placeholder: 'Логин', name: 'login', required: false }}
          errors={errors}
        />
        <Input
          type="password"
          register={register}
          data={{ placeholder: 'Пароль', name: 'password', required: false }}
          errors={errors}
        />
      </div>

      <Button variant="turquoise" classNames="w-full t-sm:w-80">
        Войти
      </Button>

      <div className="flex flex-col gap-2.5 mx-auto items-center">
        <Checkbox data={{ name: 'not-logout' }} register={register} errors={errors}>
          <div className="font-gilroy-500 text-black-100">Не выходить из системы</div>
        </Checkbox>

        <AppLink href="/reset-password/" classNames="text-violet-200 underline">
          Забыли пароль? Восстановить
        </AppLink>

        <div className="flex items-center gap-2">
          <div className="font-gilroy-500 text-black-100">Нет аккаунта?</div>
          <AppLink href="/registration/" classNames="text-violet-200 underline">
            Зарегистрироваться
          </AppLink>
        </div>
      </div>
    </AuthFormContainer>
  );
};
