import { createAsyncThunk } from '@reduxjs/toolkit';
import ProductService from 'src/services/ProductService';

export const fetchProducts = createAsyncThunk(
  'products/fetch',
  async (data: { shopId: string; groupIds?: string }, thunkAPI) => {
    try {
      const response = await ProductService.getProducts(data.shopId, data.groupIds);

      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить товары');
    }
  },
);

export const fetchStoreFilteringProducts = createAsyncThunk(
  'products/fetchStoreFilteringProducts',
  async (shopId: string, thunkAPI) => {
    try {
      const response = await ProductService.getProducts(shopId, '');

      return { shopId, products: response.data };
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить товары');
    }
  },
);

export const fetchCurrency = createAsyncThunk('products/getCurrency', async (_, thunkAPI) => {
  try {
    const response = await ProductService.getCurrency();

    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue('Не удалось загрузить список валют');
  }
});

export const fetchParameters = createAsyncThunk(
  'products/getParameters',
  async (groupId: string, thunkAPI) => {
    try {
      const response = await ProductService.getParameters(groupId);

      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить список параметров');
    }
  },
);
