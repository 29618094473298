import { FC, useState } from 'react';
import { ShopType } from 'src/config/types';
import { Button } from 'src/components/kit/Button';
import { Modal } from 'src/components/kit/Modal';
import { CreateShopForm } from 'src/components/Forms/CreateShopForm';
import { useAppDispatch } from 'src/hooks/useRedux';
import { DeleteModal } from 'src/components/Modals/DeleteModal';
import { fetchShops } from 'src/store/shops/actions';
import { selectShop } from 'src/store/shops';
import { AppToolTip } from 'src/components/AppToolTip';
import ShopService from 'src/services/ShopService';

import { ReactComponent as EditIcon } from 'src/assets/icons/kit/edit.svg';
import { ReactComponent as CloseIcon } from 'src/assets/icons/kit/close.svg';
import { ReactComponent as TgIcon } from 'src/assets/icons/kit/tg.svg';
import { ReactComponent as LogoIcon } from 'src/assets/icons/logo.svg';

import cn from 'classnames';

interface ShopCardProps {
  data: ShopType;
  classNames?: string;
}

export const ShopCard: FC<ShopCardProps> = ({ data, classNames }) => {
  const dispatch = useAppDispatch();

  const [isOpenAddModal, setIsOpenAddModal] = useState<boolean>(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);

  const openAddModal = () => setIsOpenAddModal(true);
  const closeAddModal = () => setIsOpenAddModal(false);

  const openDeleteModal = () => setIsOpenDeleteModal(true);
  const closeDeleteModal = () => setIsOpenDeleteModal(false);

  const handleDeleteShop = async () => {
    await ShopService.deleteShop(data.id);
    await dispatch(fetchShops());
  };

  const generateDeleteText = () => {
    const productsText = data.productsCount
      ? `Данный магазин сдержит ${data.productsCount} активных товаров.\n`
      : '';

    return `${productsText}Вы точно хотите удалить магазин?`;
  };

  const handleChangeSelectedShop = () => {
    dispatch(selectShop(data));
  };

  return (
    <div
      className={cn(
        'bg-white p-4 t-sm:p-8 flex flex-col t-sm:flex-row t-sm:items-end rounded-4xl',
        'gap-6 justify-between relative',
        classNames,
      )}
    >
      <div className="flex items-center gap-2 t-lg:gap-4 w-full t-sm:w-auto">
        <div className="h-28 w-28 min-w-[7rem] rounded-full overflow-hidden">
          {data.image ? (
            <img src={data.image} alt="" className="w-full h-full object-contain" />
          ) : (
            <LogoIcon className="w-full h-full " />
          )}
        </div>
        <div
          className={
            'flex flex-col gap-1.5 w-1/2 m-md:w-2/3 t-sm:w-52 t-lg:w-[27rem] d-xs:w-[38rem] d-lg:w-[40rem]'
          }
        >
          <AppToolTip
            classNames="font-gilroy-500 text-lg w-full truncate"
            id={`shop-card-name-${data.id}-tooltip`}
            text={data.name}
          />

          <AppToolTip
            classNames="font-gilroy-400 text-sm w-full text-grey-100 truncate"
            id={`shop-card-description-${data.id}-tooltip`}
            text={data.description}
          />

          <div className="flex flex-col w-fit t-lg:flex-row gap-2 m-md:items-center">
            <Button
              variant="turquoise-light"
              size="small"
              classNames="w-fit"
              onClick={openAddModal}
            >
              Редактировать
              <EditIcon className="w-3 h-3 fill-turquoise-100" />
            </Button>

            {/*<Button*/}
            {/*  href={`https://${data.tgAddress}`}*/}
            {/*  newWindow*/}
            {/*  variant="green"*/}
            {/*  size="small"*/}
            {/*  classNames="w-fit"*/}
            {/*>*/}
            {/*  Перейти в бот*/}
            {/*  <TgIcon className="w-3 h-3 fill-white" />*/}
            {/*</Button>*/}
          </div>
        </div>
      </div>

      <div className="w-full t-sm:w-fit flex flex-col gap-2.5 t-sm:flex-row t-lg:gap-5">
        <Button
          buttonVariant="link"
          variant="turquoise"
          classNames="w-full t-lg:w-fit"
          onClick={handleChangeSelectedShop}
          href={`/dashboard/shops/${data.id}`}
        >
          Каталог товаров
        </Button>
        {/*<Button buttonVariant="link" variant="turquoise-outline" classNames="w-full t-lg:w-fit">*/}
        {/*  Рассылки*/}
        {/*</Button>*/}
      </div>

      <CloseIcon
        className="w-4 h-4 cursor-pointer absolute top-4 right-4 t-lg:top-8 t-lg:right-8"
        onClick={openDeleteModal}
      />

      {isOpenDeleteModal ? (
        <Modal isOpen={isOpenDeleteModal} onClose={closeDeleteModal}>
          <DeleteModal
            text={generateDeleteText()}
            handleDelete={handleDeleteShop}
            handleClose={closeDeleteModal}
          />
        </Modal>
      ) : null}

      {isOpenAddModal ? (
        <Modal isOpen={isOpenAddModal} onClose={closeAddModal}>
          <CreateShopForm defaultValues={data} onSave={closeAddModal} />
        </Modal>
      ) : null}
    </div>
  );
};
