import { FC, useEffect, useMemo, useState } from 'react';
import { DashboardContainer } from 'src/containers/DashboardContainer';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { fetchOrderStatuses } from 'src/store/orders/actions';
import { OrderCard } from 'src/components/Cards/OrderCard';
import { EditOrderModal } from 'src/components/Modals/EditOrderModal';
import { Loader } from 'src/components/Loader';
import { fetchProducts } from 'src/store/products/actions';
import { setIsLoadingOrders, setOrdersSort } from 'src/store/orders';
import { useSearchParams } from 'react-router-dom';
import { OrderType, SortType } from 'src/config/types';
import OrderService from 'src/services/OrderService';

import { ReactComponent as ArrowBigIcon } from 'src/assets/icons/kit/arrow-big.svg';
import cn from 'classnames';

export const Orders: FC = () => {
  const dispatch = useAppDispatch();
  const {
    common: { globalSearch },
    shops: { isLoadingShops, selectedShop },
    orders: { orderStatuses, ordersSort },
  } = useAppSelector(state => state);

  const [orders, setOrders] = useState<OrderType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEditOpen, setIsEditOpen] = useState<OrderType | null>(null);

  const loadMore = async () => {
    if (!selectedShop) return;
    if (orders.length % 10) return;

    try {
      const res = await OrderService.getOrders(selectedShop?.id, ordersSort, orders.length);
      if (res?.data) {
        setOrders(prev => [...prev, ...(res.data?.orders || [])]);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleOpenEdit = order => setIsEditOpen(order);
  const handleCloseEdit = () => setIsEditOpen(null);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const orderId = searchParams.get('orderId');
    if (orderId && orders?.length) {
      const order = orders.find(order => order.id.toString() === orderId.toString());
      setIsEditOpen(order || null);
    }
  }, [orders]);

  const getOrders = async (newSort?: SortType) => {
    if (!isLoadingShops && !selectedShop) {
      dispatch(setIsLoadingOrders(false));
      return;
    }
    if (!selectedShop) return;

    setIsLoading(true);

    try {
      const res = await OrderService.getOrders(selectedShop?.id, newSort || ordersSort);
      if (res?.data) {
        setOrders(res.data?.orders || []);
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const filteredOrders = useMemo(
    () =>
      orders?.length
        ? orders?.filter(order => order.id.toString().includes(globalSearch.toLowerCase()))
        : [],
    [globalSearch, orders],
  );

  const handleChangeSort = async () => {
    if (!selectedShop) return;

    const newSort = ordersSort === 'asc' ? 'desc' : 'asc';
    dispatch(setOrdersSort(newSort));
    await getOrders(newSort);
  };

  useEffect(() => {
    (async () => {
      await getOrders();
      if (selectedShop) {
        await dispatch(fetchProducts({ shopId: selectedShop?.id, groupIds: '' }));
      }
    })();
  }, [selectedShop]);

  useEffect(() => {
    (async () => {
      if (!orderStatuses) {
        await dispatch(fetchOrderStatuses());
      }
    })();
  }, []);

  return (
    <DashboardContainer
      search
      title="Мои заказы"
      description="Здесь вы можете посмотреть заказы ваших магазинов"
      searchElement={
        <div
          className="font-gilroy-400 text-lg w-fit items-center cursor-pointer hidden t-lg:flex"
          onClick={handleChangeSort}
        >
          Дата{' '}
          <ArrowBigIcon
            className={cn(
              { '-rotate-90': ordersSort === 'asc', 'rotate-90': ordersSort === 'desc' },
              'w-4 h-4 ml-4',
            )}
          />
        </div>
      }
    >
      {isLoading ? <Loader classNames="mx-auto my-20 w-1/2 t-lg:w-1/3" /> : null}

      {!isLoading && filteredOrders.length ? (
        <div className="flex flex-col gap-5">
          {filteredOrders?.map((order, i) => (
            <OrderCard
              withObserver={i === orders.length - 2}
              loadMore={loadMore}
              key={`order-${order.id}`}
              order={order}
              openEdit={handleOpenEdit}
            />
          ))}
        </div>
      ) : null}

      {!isLoading && orders.length && !filteredOrders.length && globalSearch ? (
        <div className="my-20 text-center text-xl t-sm:text-3xl font-gilroy-500">
          По вашему запросу ничего не найдено
        </div>
      ) : null}

      {!isLoading && !orders.length ? (
        <div className="my-20 text-center text-xl t-sm:text-3xl font-gilroy-500">
          У вас нет активных заказов
        </div>
      ) : null}

      <EditOrderModal getOrders={getOrders} order={isEditOpen} onClose={handleCloseEdit} />
    </DashboardContainer>
  );
};
