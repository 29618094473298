import { FC, useState } from 'react';
import { DashboardContainer } from 'src/containers/DashboardContainer';
import { ProfileForm } from 'src/components/Forms/ProfileForm';
import { Button } from 'src/components/kit/Button';
import { Modal } from 'src/components/kit/Modal';
import { TariffChoiceModal } from 'src/components/Modals/TariffChoiceModal';
import { useTariff } from 'src/hooks/useTariff';
import { useAppSelector } from 'src/hooks/useRedux';

import cn from 'classnames';

export const Profile: FC = () => {
  const {
    user: { user },
  } = useAppSelector(state => state);

  const [openModal, setOpenModal] = useState(false);
  const [openChangeModal, setOpenChangeModal] = useState(false);

  const handleClose = () => setOpenModal(false);

  const handleChangeOpen = () => setOpenChangeModal(true);
  const handleChangeClose = () => setOpenChangeModal(false);

  const curTariff = useTariff();

  // const getDate = () => {
  //   if (!user) {
  //     return '';
  //   }
  //   const getZero = (num: number) => (num < 10 ? '0' : '');
  //   const curDate = new Date(user.subscriptionEnd);
  //
  //   const date = curDate.getDate();
  //   const month = curDate.getMonth() + 1;
  //
  //   return `${getZero(date)}${date}.${getZero(month)}${month}.${curDate.getFullYear()}`;
  // };

  // const list = [
  //   { name: 'Количество товаров:', value: curTariff?.goodsCount },
  //   {
  //     name: 'Плата за тариф:',
  //     value: curTariff
  //       ? curTariff?.price
  //         ? `${numberWithSpaces(curTariff?.price / 100)} руб./мес`
  //         : 'бесплатно'
  //       : '',
  //   },
  //   { name: 'Тариф действует до:', value: getDate() },
  // ];

  return (
    <DashboardContainer
      title="Мой профиль"
      description="Здесь вы можете изменить данные вашего профиля"
    >
      <div className="flex flex-col w-full t-sm:flex-row gap-10 t-sm:justify-between">
        <div className={cn('w-full t-sm:w-[32.5rem] flex flex-col gap-5')}>
          <div className="">
            <div className="font-gilroy-400 text-sm text-grey-100">Логин</div>
            <div className="font-gilroy-500">{user?.name}</div>
          </div>

          <div className="">
            <div className="font-gilroy-400 text-sm text-grey-100">Телефон</div>
            <div className="font-gilroy-500">{user?.phone || 'Не указан'}</div>
          </div>
          <div className="">
            <div className="font-gilroy-400 text-sm text-grey-100">Email</div>
            <div className="font-gilroy-500">{user?.email || 'Не указан'}</div>
          </div>

          <Button variant="turquoise" classNames="ml-auto" onClick={handleChangeOpen}>
            Изменить данные
          </Button>
        </div>

        {/*{isLoadingTariffs || isLoadingUser ? (*/}
        {/*  <div className="w-full t-sm:t-sm:w-[32.5rem] flex">*/}
        {/*    <Loader classNames="w-1/2 mx-auto" />*/}
        {/*  </div>*/}
        {/*) : null}*/}

        {/*{!isLoadingTariffs && !isLoadingUser && user && curTariff ? (*/}
        {/*  <div className="p-4 t-sm:p-8 bg-white rounded-3xl w-full t-sm:t-sm:w-[34rem]">*/}
        {/*    <div className="font-gilroy-400 text-xl whitespace-pre-wrap mb-5">*/}
        {/*      Ваш текущий тарифный план:{' '}*/}
        {/*      <div className="text-turquoise-100 inline font-gilroy-600">*/}
        {/*        {user.subscriptionName}*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <div className="mb-8 flex flex-col gap-0.5">*/}
        {/*      {list.map(el =>*/}
        {/*        el.value ? (*/}
        {/*          <div key={`current-tariff-${el.name}`} className="flex gap-1 text-sm">*/}
        {/*            <div className="font-gilroy-400 text-grey-100">{el.name}</div>*/}
        {/*            <div className="font-gilroy-600 text-turquoise-100">{el.value}</div>*/}
        {/*          </div>*/}
        {/*        ) : null,*/}
        {/*      )}*/}
        {/*    </div>*/}

        {/*    <div className="flex flex-col t-lg:flex-row gap-4 w-full">*/}
        {/*      <Button*/}
        {/*        variant="turquoise-outline"*/}
        {/*        classNames="whitespace-nowrap"*/}
        {/*        onClick={handleOpen}*/}
        {/*      >*/}
        {/*        Продлить оплату*/}
        {/*      </Button>*/}
        {/*      <Button variant="turquoise" classNames="whitespace-nowrap" href="/dashboard/rates">*/}
        {/*        Изменить тариф*/}
        {/*      </Button>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*) : null}*/}
      </div>

      {openModal && curTariff ? (
        <Modal isOpen={openModal} onClose={handleClose} mobileClose>
          <TariffChoiceModal initialTariff={curTariff} />
        </Modal>
      ) : null}

      {openChangeModal ? (
        <Modal isOpen={openChangeModal} onClose={handleChangeClose} mobileClose>
          <ProfileForm onClose={handleChangeClose} />
        </Modal>
      ) : null}
    </DashboardContainer>
  );
};
