import { FC, useEffect, useMemo, useState } from 'react';
import { DashboardContainer } from 'src/containers/DashboardContainer';
import { useParams } from 'react-router';
import { Button } from 'src/components/kit/Button';
import { Modal } from 'src/components/kit/Modal';
import { GroupAddForm } from 'src/components/Forms/GroupAddForm';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { fetchGroups } from 'src/store/groups/actions';
import { GroupCard } from 'src/components/kit/Cards/GroupCard';
import { Loader } from 'src/components/Loader';
import { setGlobalError } from 'src/store/common';
import { ImportCatalogForm } from 'src/components/Forms/ImportCatalogForm';
import { selectShop } from 'src/store/shops';
import { OnBoarding } from 'src/components/OnBoarding';
import { createCatalogOnBoardingState } from 'src/config/onBoardingData';
import ShopService from 'src/services/ShopService';

import { ReactComponent as ExcelImportIcon } from 'src/assets/icons/exel-import.svg';
import { ReactComponent as ExcelExportIcon } from 'src/assets/icons/exel-export.svg';

export const Groups: FC = () => {
  const dispatch = useAppDispatch();
  const {
    shops: { shops, selectedShop },
    groups: { groups, isLoadingGroups },
    common: { globalSearch },
    user: { isLoadingUser },
  } = useAppSelector(state => state);
  const { shopId } = useParams();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenImportExelModal, setIsOpenImportExelModal] = useState<boolean>(false);
  const [isOpenExportExelModal, setIsOpenExportExelModal] = useState<string>('');

  const filteredGroups = useMemo(
    () => groups.filter(group => group.name?.toLowerCase()?.includes(globalSearch.toLowerCase())),
    [globalSearch, groups],
  );

  const openModal = () => setIsOpenModal(true);
  const closeModal = () => setIsOpenModal(false);

  const openImportExelModal = () => setIsOpenImportExelModal(true);
  const closeImportExelModal = async () => {
    await dispatch(fetchGroups(shopId || ''));
    setIsOpenImportExelModal(false);
  };

  const openExportExelModal = async () => {
    setIsOpenExportExelModal('loading');
    try {
      await ShopService.exportCatalog(shopId || '')
        .then(({ data }) => {
          setIsOpenExportExelModal(data);
        })
        .catch(() => {
          setIsOpenExportExelModal('');
          dispatch(setGlobalError('Ошибка\nЧто-то пошло не так!'));
        });
    } catch (e) {}
  };
  const closeExportExelModal = () => setIsOpenExportExelModal('');

  const shop = useMemo(() => shops.find(shop => shop.id.toString() === shopId), [shops, shopId]);

  useEffect(() => {
    if (!selectedShop || isLoadingUser) {
      return;
    }

    (async () => {
      await dispatch(fetchGroups(shopId || ''));
    })();
  }, [selectedShop, isLoadingUser]);

  useEffect(() => {
    if (shop && shopId && shop?.id?.toString() === shopId?.toString()) {
      dispatch(selectShop(shop));
    }
  }, [shop]);

  const excelIconStyles =
    'opacity-60 group-hover:opacity-100 w-10 h-10 fill-green-100 transition-full';
  const excelTextStyles = 'text-blue-100 font-gilroy-500 text-sm group-hover:underline';
  const excelBlockStyles = 'flex flex-col gap-1.5 items-center cursor-pointer group';

  return (
    <DashboardContainer
      search
      searchElement={
        <Button
          id="add-group-button-onboarding"
          variant="turquoise"
          classNames="w-full t-lg:w-fit"
          onClick={openModal}
        >
          Добавить группу
        </Button>
      }
      exel={
        <div className="flex gap-2.5 t-lg:gap-5 mb-2">
          <div
            id="import-catalog-button-onboarding"
            className={excelBlockStyles}
            onClick={openImportExelModal}
          >
            <ExcelImportIcon className={excelIconStyles} />
            <div className={excelTextStyles}>Загрузить</div>
          </div>
          <div
            id="export-catalog-button-onboarding"
            className={excelBlockStyles}
            onClick={openExportExelModal}
          >
            <ExcelExportIcon className={excelIconStyles} />
            <div className={excelTextStyles}>Скачать</div>
          </div>
        </div>
      }
      title={shop?.name || ''}
      description="Здесь вы можете управлять каталогом своего магазина. Создавать, редактировать и удалять группы и подгруппы товаров"
    >
      <OnBoarding steps={createCatalogOnBoardingState} id={3} />

      {isLoadingGroups ? <Loader classNames="mx-auto my-20 w-1/2 t-lg:w-1/3" /> : null}

      {!isLoadingGroups && !groups.length && !globalSearch ? (
        <div className="my-20 text-center text-xl t-sm:text-3xl font-gilroy-500">
          В этом каталоге нет активных групп
        </div>
      ) : null}

      {!isLoadingGroups && !filteredGroups.length && globalSearch ? (
        <div className="my-20 text-center text-xl t-sm:text-3xl font-gilroy-500">
          По вашему запросу ничего не найдено
        </div>
      ) : null}

      {!isLoadingGroups && groups.length ? (
        <div className="grid grid-cols-1 t-sm:grid-cols-2 d-xs:grid-cols-3 gap-5">
          {filteredGroups?.map(group => (
            <GroupCard key={`group-${group.id}`} data={group} />
          ))}
        </div>
      ) : null}

      {isOpenModal ? (
        <Modal isOpen={isOpenModal} onClose={closeModal}>
          <GroupAddForm onSave={closeModal} shopId={shopId || ''} />
        </Modal>
      ) : null}

      {isOpenImportExelModal ? (
        <Modal
          isOpen={isOpenImportExelModal}
          onClose={closeImportExelModal}
          classNames="flex items-center justify-center"
        >
          <ImportCatalogForm shopId={shopId || ''} onSend={closeImportExelModal} />
        </Modal>
      ) : null}

      {isOpenExportExelModal ? (
        <Modal
          isOpen={!!isOpenExportExelModal}
          onClose={closeExportExelModal}
          classNames="flex flex-col items-center justify-center"
        >
          {isOpenExportExelModal !== 'loading' ? (
            <>
              <div className="mx-auto w-fit text-xl font-gilroy-500 mb-10">
                Excel файл можно скачать по ссылке
              </div>
              <Button
                variant="turquoise"
                href={isOpenExportExelModal}
                newWindow
                classNames="mx-auto"
              >
                Перейти к файлу
              </Button>
            </>
          ) : (
            <Loader classNames="mx-auto w-20 h-20" />
          )}
        </Modal>
      ) : null}
    </DashboardContainer>
  );
};
