import { ChangeEvent, Dispatch, FC, SetStateAction, useState } from 'react';
import { Carousel } from 'src/components/Carousel';

import { ReactComponent as ImgIcon } from 'src/assets/icons/kit/img.svg';
import { ReactComponent as AddPhotoIcon } from 'src/assets/icons/kit/add-photo.svg';
import cn from 'classnames';
import { BasketIconButton } from 'src/components/IconButton/BasketIconButton';
import { Modal } from 'src/components/kit/Modal';

interface ProductImagesCarouselProps {
  images: (string | File)[];
  setImages: Dispatch<SetStateAction<(string | File)[]>>;
}

export const ProductImagesCarousel: FC<ProductImagesCarouselProps> = ({ images, setImages }) => {
  const [fullImg, setFullImg] = useState<string | File | null>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.files?.length) {
      return;
    }

    const fileListArr = Array.from(e?.target?.files).map(image => image);
    setImages(prev => [...prev, ...fileListArr]);
  };

  const getSrc = (img: File | string) => {
    if (typeof img === 'string') {
      return img;
    }

    return URL.createObjectURL(img as File);
  };

  const handleRemove = index => {
    setImages(prev => prev.filter((_, i) => i !== index));
  };

  const handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const fileListArr = Array.from(e?.dataTransfer?.files).map(image => image) as File[];
      setImages(prev => [...prev, ...fileListArr]);
    }
  };

  return (
    <Carousel itemWidth={80}>
      {images.map((img, i) => (
        <div
          key={`carousel-products-image-${i}`}
          className={
            'min-w-[5rem] w-20 h-20 flex items-center justify-center rounded-xl ' +
            'relative overflow-hidden'
          }
        >
          <BasketIconButton
            image="basket"
            onClick={() => handleRemove(i)}
            variant="sm"
            classNames="absolute top-1.5 right-1.5"
          />
          <BasketIconButton
            image="loupe"
            onClick={() => setFullImg(img)}
            variant="sm"
            classNames="absolute top-1.5 left-1.5"
          />
          <img src={getSrc(img)} alt="" className="w-full h-full object-cover" />
        </div>
      ))}
      <label
        onDrop={handleDrop}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        className={cn(
          'min-w-[5rem] w-20 h-20 flex items-center justify-center bg-grey-800',
          'rounded border border-dashed border-grey-100 cursor-pointer ',
          'transition-full hover:border-solid group',
        )}
      >
        <AddPhotoIcon className="w-10 h-10 transition-full stroke-grey-100 group-hover:stroke-turquoise-100" />

        <input
          multiple
          onChange={handleChange}
          className="hidden"
          type="file"
          accept="image/png, image/jpeg, image/jpg"
        />
      </label>
      {fullImg && (
        <Modal isOpen={!!fullImg} onClose={() => setFullImg(null)}>
          <div className="max-h-[80dvh] max-w-[90dvh] pt-8">
            <img
              src={getSrc(fullImg)}
              alt=""
              className="w-full h-full object-contain rounded-lg "
            />
          </div>
        </Modal>
      )}
    </Carousel>
  );
};
