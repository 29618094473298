import { HeadItemType, MonthType, RateType, TabType } from 'src/config/types';

export const defaultShopColors = {
  color: 'BFEEF4',
  buttonsColor: 'EA58D5',
  textColor: 'FFFFFF',
  discountColor: '000000',
};

export const lorem =
  'lorem text lorem text lorem text lorem text lorem text lorem text lorem text lorem text lorem text lorem text lorem text lorem text lorem text lorem text lorem text lorem text lorem text lorem text lorem text lorem text lorem text lorem text lorem text lorem text lorem text lorem text lorem text ';

export const orderStatusColors = {
  1: 'text-blue-100',
  2: 'text-grey-100',
  3: 'text-green-100',
  4: 'text-pink-100',
};

export const months: MonthType[] = [
  { name: 'Месяц', value: 1 },
  { name: '3 месяца', value: 3 },
  { name: 'Полгода', value: 6 },
  { name: 'Год', value: 12 },
];

export const rates: RateType[] = [
  {
    name: 'Лайт',
    description: 'Вы можете загружать\nдо 5 товаров',
    price: 'Бесплатно',
  },
  {
    name: 'Старт',
    description: 'Вы можете загружать\nдо 30 товаров',
    price: '999 руб./мес',
  },
  {
    name: 'Профи',
    description: 'Вы можете загружать\nдо 100 товаров',
    price: '2 499 руб./мес',
  },
  {
    name: 'Бизнес',
    description: 'Вы можете загружать\nдо 500 товаров',
    price: '4 999 руб./мес',
  },
  {
    name: 'Корпоративный',
    description: 'Вы можете загружать\nболее 500 товаров',
    price: 'Индивидуально',
  },
];

export const mainTabs: TabType[] = [
  {
    name: 'Cоздать группу',
    value: 'group',
  },
  {
    name: 'Cоздать карточку товара',
    value: 'product',
  },
];

export const productShowTypes: TabType[] = [
  {
    name: 'Крупные значки',
    value: 'big',
  },
  {
    name: 'Мелкие значки',
    value: 'small',
  },
  {
    name: 'Список',
    value: 'list',
  },
];

export const headItems: HeadItemType[] = [
  {
    name: 'Мой магазин',
    route: '/dashboard/shops',
    id: 'my-shop-button-onboarding',
  },
  {
    name: 'Профиль',
    route: '/dashboard/profile',
    id: 'profile-button-onboarding',
  },
  // {
  //   name: 'Тарифы',
  //   route: '/dashboard/rates',
  //   id: 'tariff-button-onboarding',
  // },
  // {
  //   name: 'FAQ',
  //   route: '/dashboard/faq',
  //   id: 'faq-button-onboarding',
  // },
  {
    name: 'Обращения',
    route: '/dashboard/support',
    id: 'chats-button-onboarding',
  },
];

export const shortMonthsNames: string[] = [
  'янв.',
  'февр.',
  'март.',
  'апр.',
  'мая',
  'июня',
  'июля',
  'авг.',
  'сент.',
  'окт.',
  'нояб.',
  'дек.',
];

export const monthsNames: string[] = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
];

export const messages: string[] = [
  'Добрый день! У меня случилилась проблема(((',
  'Добрый день! Это очень печальная новость. Ситуация неприятная и я понимаю Вам тяжело, расскажете подробнее о своей проблеме мы постараемся решить ее как можно быстрее!',
  'В телеграмм написал незнакомый чувак, с неизвестного номера🕵️‍ Обратился ко мне по имени, говорит привет. Я ответила привет, ты кто?🫣 А он «если я тебе скажу кто я, ты никому не расскажешь?». Я ответила «всем расскажу. Мужу расскажу» 😡. Смотрю, из чата тут же исчезли и его, и мои сообщения😂 ну я его ещё заблокировала на всякий случай. И все это случилось сразу после регистрации в вашем приложении!👿',
  'Наш сайт не занимается поиском по номеру телефона. На всякий случай мы сотрем введенные Вами номера, так как публикуя их, Вы становитесь легкой жертвой для телефонных мошенников.',
];
