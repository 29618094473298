import { FC, useEffect, useRef, useState } from 'react';
import { SelectItemType, SelectType } from 'src/config/types';
import { useClickOutside } from 'src/hooks/useClickOutside';

import { ReactComponent as ArrowSmall } from 'src/assets/icons/kit/arrow-small.svg';

import cn from 'classnames';

interface SelectProps {
  data: SelectType;
  id?: string;
  setValue?: (name: string, value: string) => void;
  register?: (name: string, RegisterOptions?) => { onChange; onBlur; name; ref };
  classNames?: string;
  isError?: boolean;
  value?: SelectItemType;
  setStateValue?: (arg: SelectItemType) => void;
  ruled?: boolean;
  size?: 'vase' | 'small';
  variant?: 'blue' | 'white';
}

export const Select: FC<SelectProps> = ({
  data,
  id,
  setValue,
  classNames,
  isError,
  value,
  setStateValue,
  ruled,
  size = 'base',
  variant = 'blue',
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<SelectItemType | null>(
    data.defaultValue || value || null,
  );

  useEffect(() => {
    if (value) {
      setSelectedValue(value);
    }
  }, [value]);

  useClickOutside(ref, () => setIsOpen(false));

  const sizeVariants = {
    base: cn({ 'rounded-b-xl': !isOpen }, 'rounded-t-xl px-5 t-sm:px-8 py-3'),
    small: cn({ 'rounded-b-xl': !isOpen }, 'rounded-t-xl p-2 text-sm'),
  };

  const colorVariants = {
    blue: cn({
      'border-transparent bg-grey-800 hover:bg-grey-900': !Boolean(isError) && !isOpen,
      'bg-grey-900': isOpen,
    }),
    white: cn({
      'shadow-black-500 bg-white': isOpen,
      'bg-transparent': !isOpen,
    }),
  };

  const handleOpen = () => setIsOpen(prev => !prev);

  const handleSelect = (item: SelectItemType) => {
    !ruled && setSelectedValue(item);

    if (setStateValue) {
      setStateValue(item);
      return;
    }

    setValue && setValue(data.name, item.value);
  };

  const renderImage = (image?: string) =>
    image ? (
      <div className="min-w-[1.5rem] w-6 h-6 mr-2.5 rounded-full overflow-hidden">
        <img className="w-full h-full object-cover" src={image} alt="" />
      </div>
    ) : null;

  const widthStyles = 'whitespace-nowrap truncate';

  return (
    <div
      id={id}
      className={cn(
        classNames,
        {
          'text-pink-100 border-pink-100 bg-white': Boolean(isError),
        },
        'w-full font-gilroy-400 text-base cursor-pointer relative flex items-center',
        'justify-between',
        sizeVariants[size],
        colorVariants[variant],
        value?.styles || '',
      )}
      ref={ref}
      onClick={handleOpen}
    >
      <>
        <div className="flex items-center w-full">
          {renderImage(selectedValue?.image)}
          <div
            className={widthStyles}
            style={{ width: `calc(100% - ${selectedValue?.image ? 54 : 20}px)` }}
          >
            {selectedValue?.name || data.placeholder}
          </div>
          {selectedValue?.rightComponent}
          <ArrowSmall
            className={cn(
              { 'rotate-180': isOpen },
              'stroke-path-grey-100 w-3 h-3 min-w-[0.75rem] transition-full ml-auto',
            )}
          />
        </div>

        {isOpen ? (
          <div
            className={cn(
              {
                'shadow-black-500': variant === 'white',
                'border border-white-100': variant === 'blue',
              },
              'absolute z-110 rounded-b-xl top-full max-h-[14rem] left-0 w-full bg-white overflow-hidden',
            )}
          >
            <div className="w-full max-h-[14rem] overflow-auto scrollbar-hide flex flex-col">
              {data.list.map((el, i) => (
                <div
                  className={cn(
                    {
                      'py-3 px-4 t-sm:px-8': size === 'base',
                      'p-2': size === 'small',
                      'text-black bg-grey-800': el.value === selectedValue?.value,
                      'hover:text-black text-grey-100 hover:bg-grey-800':
                        el.value !== selectedValue?.value && !el.styles,
                    },
                    'font-gilroy-400 cursor-pointer flex items-center',
                    el.styles || '',
                  )}
                  key={`select-item-${data.name}-${i}`}
                  onClick={() => handleSelect(el)}
                >
                  {renderImage(el?.image)}
                  <div
                    className={widthStyles}
                    style={{ width: `calc(100% - ${selectedValue?.image ? 34 : 20}px)` }}
                  >
                    {el.name}
                  </div>
                  {el?.rightComponent}
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </>
    </div>
  );
};
